/*

 cr.viewModelFactory.plugins.advancedSearch

 requires: cr.viewModelFactory.plugins.searchVm

 The advanced search is applied to a viewmodel, in over to help implement all the various searches centralReach uses
 from time to time, as well as keep these values synced with the url hash.

 For each search term a vm and a display property are defined, which may be one in the same.  Each of these properties
 is added to the viewModel.  The vm is the actual value of the search term, while display is the value that's displayed
 in the ui.  For example, for the client search entry, vm is clientId, and display is clientName.

 As searches are activated, the search entry will automatically show up in the filter bar.  Whether a search item shows
 up is determined by whether the vm property is active.  If active the display field is what will actually show up in the
 filter bar.  When searching for client 2396, the client entry will show up, and will show <loading> until
 the mid tier returns and mapSearchValuesFromResponse (discussed below) is called, thereby giving clientName a value
 of Kenneth Penmen, which then shows in the filter bar.

----------------------------------------------------------------------------------------------------------------------

Creation options (object passed to plugin)

    Coming soon ...... just check the code for now :-)

API:

  -  syncWithHash(options)
        Updates the searchVm to be in sync with the current hash values.  Is usually called from within processHash.
        Options can have a skip array of properties to skip.
  -  mapSearchValuesFromResponse(resp)
        Goes through a response object sent from the mid tier, and maps over all display fields from the response object,
        over to the view model.  After loading a list with, say, a clientId argument, the mid tier will usually send back
        a clientName to match the clientId, which will be mapped when this method is called
  -  getRequestObject()
        Gets a request object to send to the mid tier.  Adds simple properties, and also stringifies collections
  -  fireOffSearch()
        Updates the hash with the current state of the searchVm.  Not usually needed in regular usage.
  -  anyFiltersActive()
        Self explanatory
  -  filterBarVisible
        Similar to above, but ignores search properties that are excluded from the filter bar (hence the name)

  -  searchPropertyIsActive(prop[, suppressFlag])
        Determines whether a given vm property is active.  If it's an array, returns whether any values are in it; id it's
        a regular search value, determines whether it has any value, and if so, whether it's different from the default value.
        For include/exclude properties, you can optionally include a second argument: "suppressInclude" will make the
        function return false if the property only has include values and doesn't have exclude values. "suppressExclude"
        has the converse effect. When no arg is given, either include or exclude values are considered (default).
  -  pageUp()
        Pages up, and updates the hash
  -  pageDown()
        Pages down and updates the hash
  -  setPageSize(pageSize)
        Sets page size, resets to page 1, and updates the hash.  Also calls onSearchChanging so you can catch the update
        even if nothing on the url hash is updated

  -  removeAllFilters()
        Resets the searchVm and resets the hash

  -  setFilterItem(prop, val)
        Sets the HASH for property `prop` to value `val`.  If val is null, or the defaultValue then the hash is removed.
        Note, this method does not update the searchVm; it only updates the hash.
  -  clearFilterItem(vm, evt)
        Clears the search filter item attached to the dom element that triggered the event.  This method is primarily
        intended to be called from the filter info template generated by this plugin.

  -  setFilterItems(obj)
        Sets the hash to be all keyValue pairs in `obj`.  This does not take into account defaultValues.  The reason
        essentially boils down to being that this is essentially used for loading a set of saved filters, and the sprocs
        that do that obviate the need for this.  If it's ever needed, it'll be added
  -  toggleFilterCollectionItem(prop, val)
        Toggles value `val` for collection filter `prop`.  If it's included, it becomes excluded, and vice versa.
  -  includeItem(item, e)
        Switches an excluded collection item to be included
  -  excludeItem(item, e)
        Switches an included collection item to be excluded
  -  updateHashCollection(collectionRootName)
        Called when a search collection has been updated somehow, and the hash needs to therefore be updated
  -  removeFromFilterCollection(item, e)
        Removes item from

 */

define("framework/koMapper/plugins/advancedSearch", [], function() {
  ko.bindingHandlers.advancedSearchFilterBar = {
    init: function(element, valueAccessor) {
      var searchVm = valueAccessor();

      $(element).html(getSearchHtml(searchVm._collectionArr, searchVm._advancedSearchConfig));
      ko.applyBindingsToDescendants(searchVm, element);
      ko.applyBindingsToNode(element, {
        visible: ko.computed(function() {
          return searchVm.filterBarVisible();
        })
      });
      return { controlsDescendantBindings: true };
    }
  };

  ko.bindingHandlers.advancedSearchLazyFilterBar = {
    init: function(element, valueAccessor) {
      var searchVm = valueAccessor();

      searchVm.advancedSearchMessageBus.subscribe("*", "search-values-updated-out-of-hash-cycle", updateBar);
      searchVm.advancedSearchMessageBus.subscribe("*", "search-values-updated", updateBar);
      searchVm.advancedSearchMessageBus.subscribe("*", "search-value-displays-updated", updateBar);

      function updateBar() {
        if (!searchVm.filterBarVisible()) {
          clearFilterBar();
          $(element).hide();
          return;
        }

        let { singleFilterItems, toggleableFilterItems, multiFilterItems } = getLazyFilterBarContent.call(searchVm);
        //new version does not add delegatedHandler - add it yourself IF not already present. Ie, if already there no need to call methods twice.
        let root = $('<div class="filters search-filters"></div>');
        root.append(singleFilterItems.concat(toggleableFilterItems).concat(multiFilterItems));

        clearFilterBar();
        $(element).append(root);
        ko.applyBindingsToDescendants(searchVm, element);
        $(element).show();
      }

      function clearFilterBar() {
        ko.cleanNode(element);
        $(element).empty();
      }
    }
  };

  function syncArrayFilter(arrayFilter, include, exclude, vmToUse) {
    include = include
      ? include.split("-").map(function(id) {
          return +id;
        })
      : [];
    exclude = exclude
      ? exclude.split("-").map(function(id) {
          return +id;
        })
      : [];

    var allFilteredIds = include.concat(exclude).map(function(val) {
        return +val;
      }),
      currentIds = arrayFilter().map(function(o) {
        return +o.id();
      }),
      idsToRemove = currentIds.filter(function(id) {
        return allFilteredIds.indexOf(id) === -1;
      }),
      map = {};

    if (idsToRemove.length) {
      arrayFilter.remove(function(obj) {
        return idsToRemove.indexOf(obj.id()) !== -1;
      });
      this.isDirty(true);
    }
    arrayFilter().forEach(function(obj) {
      map[+obj.id()] = obj;
    });

    include.forEach(function(id) {
      if (!map[id]) {
        arrayFilter.push(new vmToUse(id));
        this.isDirty(true);
      } else if (!map[id].isIncluded()) {
        map[id].isIncluded(true);
        this.isDirty(true);
      }
    }, this);

    exclude.forEach(function(id) {
      if (!map[id]) {
        arrayFilter.push(new vmToUse(id, false));
        this.isDirty(true);
      } else if (map[id].isIncluded()) {
        map[id].isIncluded(false);
        this.isDirty(true);
      }
    }, this);
  }

  var advancedSearchResultsMap = {
    text: {
      vm: "search",
      ui: "Text Search",
      display: "search",
      displayClasses: function() {
        return "far fa-fw fa-search";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-search"></i> Run text search of: ' + item.name;
      }
    },
    textActive: {
      vm: "search",
      ui: "Text Search",
      display: "search",
      displayClasses: function() {
        return "far fa-fw fa-search";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-search"></i> Run text search of: ' + item.name + ' <span>Running <i class="fa fa-spin fa-spinner"></i></span>';
      }
    },
    resource: {
      vm: "resourceId",
      ui: "Resource",
      display: "resourceName",
      displayClasses: function() {
        return "far fa-fw fa-file";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-file"></i> Resource: ' + item.name;
      }
    },
    bundlelabel: {
      vm: "bundleLabelId",
      ui: "Bundle Label",
      display: "bundleLabelName",
      displayClasses: function() {
        return "far fa-fw fa-tag";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-tag"></i> Bundle label: ' + item.name;
      }
    },
    claimLabel: {
      vm: "claimLabelId",
      ui: "Claim Label",
      display: "claimLabelName",
      displayClasses: function() {
        return "far fa-fw fa-tag";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-tag"></i> Claim label: ' + item.name;
      }
    },
    billingLabel: {
      vm: "billingLabelId",
      ui: "Billing Label",
      display: "billingLabelName",
      displayClasses: function() {
        return "far fa-fw fa-tag";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-tag"></i> Billing label: ' + item.name;
      }
    },
    paymentLabel: {
      vm: "paymentLabelId",
      ui: "Payment Label",
      display: "paymentLabelName",
      displayClasses: function() {
        return "far fa-fw fa-tag";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-tag"></i> Payment label: ' + item.name;
      }
    },
    contactlabel: {
      vm: "contactLabelId",
      ui: "Contact Label",
      display: "contactLabelName",
      displayClasses: function() {
        return "far fa-fw fa-tag";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-tag"></i> Contact label: ' + item.name;
      }
    },
    codelabel: {
      vm: "codeLabelId",
      ui: "Code Label",
      display: "codeLabelName",
      displayClasses: function() {
        return "far fa-fw fa-tag";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-tag"></i> Code label: ' + item.name;
      }
    },
    resourcelabel: {
      vm: "resourceLabelId",
      ui: "Resource Label",
      display: "resourceLabelName",
      displayClasses: function() {
        return "far fa-fw fa-tag";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-tag"></i> Resource label: ' + item.name;
      }
    },
    messagelabel: {
      vm: "messageLabelId",
      ui: "Message Label",
      display: "messageLabelName",
      displayClasses: function() {
        return "far fa-fw fa-tag";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-tag"></i> Message label: ' + item.name;
      }
    },
    tasklabel: {
      vm: "taskLabelId",
      ui: "Task Label",
      display: "taskLabelName",
      displayClasses: function() {
        return "far fa-fw fa-tag";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-tag"></i> Task label: ' + item.name;
      }
    },
    notelabel: {
      vm: "noteLabelId",
      ui: "Note Label",
      display: "noteLabelName",
      displayClasses: function() {
        return "far fa-fw fa-tag";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-tag"></i> Note label: ' + item.name;
      }
    },
    invoice: {
      vm: "invoiceId",
      ui: "Invoice",
      display: "invoiceName",
      displayClasses: function() {
        return "far fa-fw fa-dollar-sign";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-dollar-sign"></i> Invoice #' + item.id + " (Date: " + item.name + ")";
      }
    },
    group: {
      vm: "groupId",
      ui: "Group",
      display: "groupName",
      displayClasses: function() {
        return "fas fa-fw fa-users";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-users"></i> Group #' + item.name;
      }
    },
    paymentreference: {
      vm: "paymentReference",
      ui: "Payment Reference",
      display: "paymentReferenceName",
      displayClasses: function() {
        return "far fa-fw fa-link";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-link"></i> Payment reference: ' + item.name;
      }
    },
    principal: {
      vm: "principalId",
      ui: "Principal",
      display: "principalName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Principal: ' + item.name;
      }
    },
    manager: {
      vm: "managerId",
      ui: "Manager",
      display: "managerName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Manager: ' + item.name;
      }
    },
    implementer: {
      vm: "implementerId",
      ui: "Locked For",
      display: "implementerName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Implementer: ' + item.name;
      }
    },
    billing: {
      vm: "billingId",
      ui: "Billing",
      display: "billingName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Billing: ' + item.name;
      }
    },
    referring: {
      vm: "referrerId",
      ui: "Referring",
      display: "referringName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Referring: ' + item.name;
      }
    },
    providerSupplier: {
      vm: "providerSupplierId",
      ui: "Provider Supplier",
      display: "providerSupplierName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Provider Supplier: ' + item.name;
      }
    },
    ordering: {
      vm: "orderingId",
      ui: "Ordering",
      display: "orderingName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Ordering: ' + item.name;
      }
    },
    supervising: {
      vm: "supervisingId",
      ui: "Supervising",
      display: "supervisingName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Supervising: ' + item.name;
      }
    },
    facility: {
      vm: "facilityId",
      ui: "Facility",
      display: "facilityName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Facility: ' + item.name;
      }
    },
    provider: {
      vm: "providerId",
      ui: "Provider",
      display: "providerName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Provider: ' + item.name;
      }
    },
    client: {
      vm: "clientId",
      ui: "Client",
      display: "clientName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Client: ' + item.name;
      }
    },
    contact: {
      vm: "contactId",
      ui: "Contact",
      display: "contactName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Contact: ' + item.name;
      }
    },
    createdbycontact: {
      vm: "createdByContactId",
      ui: "Created by",
      display: "createdByContactName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Creator: ' + item.name;
      }
    },
    billingentry: {
      vm: "billingEntryId",
      ui: "Billing Entry",
      display: "billingEntryName",
      displayClasses: function() {
        return "far fa-fw fa-dollar-sign";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-dollar-sign"></i> Entry ID: ' + item.id + " from " + item.name;
      }
    },
    feeschedule: {
      vm: "feeScheduleId",
      ui: "Fee Schedule",
      display: "feeScheduleName",
      displayClasses: function() {
        return "far fa-fw fa-dollar-sign";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-dollar-sign"></i> Fee schedule: ' + item.name;
      }
    },
    insurance: {
      vm: "insuranceId",
      ui: "Insurance",
      display: "insuranceName",
      displayClasses: function() {
        return "fas fa-fw fa-medkit";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-medkit"></i> Insurance: ' + item.name;
      }
    },
    location: {
      vm: "locationId",
      ui: "Location",
      display: "locationName",
      displayClasses: function() {
        return "fas fa-fw fa-map-marker";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-map-marker"></i> Location: ' + item.name;
      }
    },
    insuranceCompany: {
      vm: "insuranceCompanyId",
      ui: "Insurance Company",
      display: "insuranceCompanyName",
      displayClasses: function() {
        return "fas fa-fw fa-medkit";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-medkit"></i> Insurance company: ' + item.name;
      }
    },
    sharedPayor: {
      vm: "sharedPayorId",
      ui: "Shared Payor",
      display: "sharedPayorName",
      displayClasses: function() {
        return "fas fa-fw fa-medkit";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-medkit"></i> Shared payor: ' + item.name;
      }
    },
    creditCard: {
      vm: "creditCardId",
      ui: "Credit Card",
      display: "creditCardName",
      displayClasses: function() {
        return "fas fa-fw fa-medkit";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-medkit"></i> Credit card payor: ' + item.name;
      }
    },
    servicecode: {
      vm: "serviceCodeId",
      ui: "Service Code",
      display: "serviceCodeName",
      displayClasses: function() {
        return "far fa-fw fa-wrench";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-wrench"></i> Service code: ' + item.name;
      }
    },
    invoiceType: {
      vm: "invoiceType",
      ui: "Invoice",
      display: "invoiceTypeName",
      displayClasses: function() {
        return "fas fa-fw fa-filter";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-filter"></i> Invoice type: ' + item.name;
      }
    },
    claim: {
      vm: "claimId",
      ui: "Claim",
      display: "claimName",
      displayClasses: function() {
        return "fas fa-fw fa-medkit";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-medkit"></i> Claim ID: ' + item.id + " from " + item.name;
      }
    },
    authorization: {
      vm: "authorizationId",
      ui: "Auth Code",
      display: "authorizationName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return "Auth code: " + item.name;
      }
    },
    authorizationSettings: {
      vm: "authorizationSettingsId",
      ui: "Auth Group",
      display: "authorizationSettingsName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return "Auth group: " + item.name;
      }
    },
    authorizationResource: {
      vm: "authorizationResourceId",
      ui: "Auth Doc",
      display: "authorizationResourceName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return "Auth doc: " + item.name;
      }
    },
    module: {
      vm: "module",
      ui: "Module",
      display: "moduleName",
      displayClasses: function() {
        return "fas fa-fw fa-medkit";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-medkit"></i> Module: ' + item.name;
      }
    },
    shared: {
      vm: "shared",
      ui: "Shared",
      display: "shared",
      displayClasses: function() {
        return "fas fa-fw fa-filter";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-filter"></i> Shared: ' + item.name;
      }
    },

    channel: {
      vm: "channel",
      ui: "Channel",
      display: "channelDisplay",
      displayClasses: function() {
        return "fas fa-fw fa-filter";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-filter"></i> Channel: ' + item.name;
      }
    },
    action: {
      vm: "action",
      ui: "Action",
      display: "action",
      displayClasses: function() {
        return "fas fa-fw fa-filter";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-filter"></i> Action: ' + item.name;
      }
    },

    inactiveEmployees: {
      vm: "inactiveEmployees",
      ui: "Include Inactive Employees",
      display: "inactiveEmployees",
      displayClasses: function() {
        return "far fa-fw fa-asterisk";
      }
    },
    excludeInactiveClients: {
      vm: "excludeInactiveClients",
      ui: "Exclude Inactive Clients",
      display: "excludeInactiveClients",
      displayClasses: function() {
        return "far fa-fw fa-asterisk";
      }
    },

    author: {
      vm: "authorId",
      ui: "Author",
      display: "authorName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Author: ' + item.name;
      }
    },

    state: {
      vm: "state",
      ui: "State",
      display: "state",
      displayClasses: function() {
        return "fas fa-fw fa-map-marker";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-map-marker"></i> State: ' + item.name;
      }
    },
    city: {
      vm: "city",
      ui: "City",
      display: "city",
      displayClasses: function() {
        return "fas fa-fw fa-map-marker";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-map-marker"></i> City: ' + item.name;
      }
    },
    zip: {
      vm: "zip",
      ui: "Zip",
      display: "zipName",
      displayClasses: function() {
        return "fas fa-fw fa-map-marker";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-map-marker"></i> Zip: ' + item.name;
      }
    },

    paymentTypeId: {
      vm: "paymentTypeId",
      ui: "Payment Type",
      display: "paymentTypeName",
      displayClasses: function() {
        return "far fa-fw fa-money-bill-alt";
      },
      option: function(item) {
        return '<i title="Filter by this payor name" class="fas fa-fw fa-user"></i> Payment type: ' + item.name;
      }
    },

    signed: {
      vm: "signed",
      ui: "Signed",
      display: "signed",
      displayClasses: function() {
        return "fa fa-fw fa-leaf";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-leaf"></i> Signed timesheets';
      }
    },

    signedProvider: {
      vm: "signedProvider",
      ui: "Signed Provider",
      display: "signedProviderName",
      displayClasses: function() {
        return "fa fa-fw fa-leaf";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-leaf"></i> Filter by: ' + item.name;
      }
    },
    signedClient: {
      vm: "signedClient",
      ui: "Signed Client",
      display: "signedClientName",
      displayClasses: function() {
        return "fa fa-fw fa-leaf";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-leaf"></i> Filter by: ' + item.name;
      }
    },

    assignedTo: {
      vm: "assignedTo",
      ui: "Assigned to",
      display: "assignedToName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Assigned to: ' + item.name;
      }
    },
    assignedNotMoreThanThisManyDaysAgo: {
      vm: "assignedNotMoreThanThisManyDaysAgo",
      ui: "Assigned",
      display: "assignedNotMoreThanThisManyDaysAgo_filterSummaryDisplayString",
      displayClasses: function() {
        return "fas fa-fw fa-filter";
      }
    },
    completionPercent: {
      vm: "completionPercent",
      ui: "% Complete",
      display: "completionPercent_filterSummaryDisplayString",
      displayClasses: function() {
        return "fas fa-fw fa-filter";
      }
    },
    deletedBy: {
      vm: "deletedBy",
      ui: "Deleted by",
      display: "deletedByName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Deleted by: ' + item.name;
      }
    },
    createdBy: {
      vm: "createdBy",
      ui: "Created by",
      display: "createdByName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Created by: ' + item.name;
      }
    },
    lastUpdatedById: {
      vm: "lastUpdatedById",
      ui: "Last updated by",
      display: "lastUpdatedByName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Last updated by: ' + item.name;
      }
    },
    completedBy: {
      vm: "completedBy",
      ui: "Completed by",
      display: "completedByName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Completed by: ' + item.name;
      }
    },
    onBehalfOf: {
      vm: "onBehalfOf",
      ui: "On Behalf of",
      display: "onBehalfOfName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> On Behalf of: ' + item.name;
      }
    },
    appliedBy: {
      vm: "appliedBy",
      ui: "Applied by",
      display: "appliedByName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Applied by: ' + item.name;
      }
    },

    task: {
      vm: "taskId",
      ui: "Task ID",
      display: "taskId",
      displayClasses: function() {
        return "fa fa-fw fa-tasks";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-tasks"></i> ' + item.name;
      }
    },
    tasksFilter: {
      vm: "tasksFilter",
      ui: "Task Filter",
      display: "tasksFilter",
      displayClasses: function() {
        return "fa fa-fw fa-tasks";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-tasks"></i> Tasks: ' + item.name;
      }
    },
    ticketsfromorg: {
      vm: "ticketsfromorg",
      ui: "Tickets From",
      display: "ticketsfromorgName",
      displayClasses: function() {
        return "fa fa-fw fa-tasks";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-tasks"></i> Tickets From Org: ' + item.name;
      }
    },
    templateIdLocal: {
      vm: "templateIdLocal",
      ui: "Task Template",
      display: "templateName",
      displayClasses: function() {
        return "fa fa-fw fa-copy";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-copy"></i> Template: ' + item.name;
      }
    },
    internalCaseId: {
      vm: "internalCaseId",
      ui: "Internal Case Id",
      display: "internalCaseId",
      displayClasses: function() {
        return "fa fa-fw fa-tasks";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-tasks"></i> Internal Case Id: ' + item.name;
      }
    },

    billStatus: {
      vm: "billStatus",
      ui: "Billing Status",
      display: "billStatusName",
      displayClasses: function() {
        return "fa fa-fw fa-plus-square";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-plus-square"></i> Claim status:' + item.name;
      }
    },
    voidStatus: {
      vm: "voidStatus",
      ui: "Void Status",
      display: "voidStatusName",
      displayClasses: function() {
        return "fa fa-fw fa-plus-square";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-plus-square"></i> Void status:' + item.name;
      }
    },
    lockStatus: {
      vm: "lockStatus",
      ui: "Lock Status",
      display: "lockStatusName",
      displayClasses: function() {
        return "fa fa-fw fa-lock";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-lock"></i> Lock status:' + item.name;
      }
    },
    copayStatus: {
      vm: "copayStatus",
      ui: "Patient Responsibility Status",
      display: "copayStatusName",
      displayClasses: function() {
        return "fas fa-fw fa-user-md";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user-md"></i> Filter by:' + item.name;
      }
    },
    isCopay: {
      vm: "isCopay",
      ui: "Patient Responsibility Status",
      display: "isCopay",
      displayClasses: function() {
        return "fas fa-fw fa-user-md";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user-md"></i> Filter by:' + item.name;
      }
    },
    paymentType: { vm: "paymentType", ui: "Payment Type", display: "paymentTypeDisplay", displayNotMapped: true, displayClasses: $.noop },

    course: {
      vm: "course",
      ui: "Appointment",
      display: "courseName",
      displayClasses: function() {
        return "fa fa-fw fa-calendar";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-calendar"></i> Filter by:' + item.name;
      }
    },
    courseDate: {
      vm: "courseDate",
      ui: "Date",
      display: "courseDateName",
      displayClasses: function() {
        return "fa fa-fw fa-calendar";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-calendar"></i> Filter by:' + item.name;
      }
    },
    courseProvider: {
      vm: "courseProvider",
      ui: "Provider",
      display: "courseProviderName",
      displayClasses: function() {
        return "fa fa-fw fa-calendar";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-calendar"></i> Filter by:' + item.name;
      }
    },

    lastBilledDate: {
      vm: "lastBilled",
      ui: "Last Billed",
      display: "lastBilledName",
      displayClasses: function() {
        return "fa fa-fw fa-calendar-o";
      }
    },
    lastPaidDate: {
      vm: "lastPaid",
      ui: "Last Paid",
      display: "lastPaidName",
      displayClasses: function() {
        return "fa fa-fw fa-calendar-o";
      }
    },

    resourceCount: {
      vm: "resourceCount",
      ui: "Files Attached",
      display: "resourceCountName",
      displayClasses: function() {
        return "fa fa-fw fa-file";
      }
    },
    email: {
      vm: "email",
      ui: "Email",
      display: "email",
      displayClasses: function() {
        return "fa fa-fw fa-envelope-o";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-envelope-o"></i> ' + item.name;
      }
    },
    filter: {
      vm: "filter",
      ui: "Filter",
      display: "filterName",
      displayClasses: function() {
        return "fas fa-fw fa-filter";
      }
    },
    ado: {
      vm: "ado",
      ui: "Show",
      display: "ado",
      displayClasses: function() {
        return "fas fa-fw fa-filter";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-filter"></i> Show' + item.name;
      }
    }, // Archived, Deleted, Other.  Valid values:  'archived', 'deleted', ''.  "Other" in this case is a reference to the majority of records - i.e. those that are neither archived nor deleted.
    fileType: {
      vm: "fileType",
      ui: "File Type",
      display: "fileTypeName",
      displayClasses: function() {
        return "far fa-fw fa-copy";
      }
    },

    sentBy: {
      vm: "sentBy",
      ui: "Sent by",
      display: "sentByName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Sent by: ' + item.name;
      }
    },
    sentTo: {
      vm: "sentTo",
      ui: "Sent to",
      display: "sentToName",
      displayClasses: function() {
        return "fas fa-fw fa-user";
      },
      option: function(item) {
        return '<i class="fas fa-fw fa-user"></i> Sent to: ' + item.name;
      }
    },

    age: {
      vm: "age",
      ui: "Age",
      display: "ageName",
      displayClasses: function() {
        return "fa fa-fw fa-child";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-child"></i> Age: ' + item.name;
      }
    },

    gender: {
      vm: "gender",
      ui: "Gender",
      display: "genderName",
      displayClasses: function() {
        return "fa fa-fw fa-heart";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-heart"></i> Gender: ' + item.name;
      }
    },

    language: {
      vm: "languageId",
      ui: "Language",
      display: "languageName",
      displayClasses: function() {
        return "fa fa-fw fa-language";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-language"></i> Speaks: ' + item.name;
      }
    },
    radius: {
      vm: "radius",
      ui: "Radius",
      display: "radius",
      displayClasses: function() {
        return "fa fa-fw fa-arrows-h";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-arrows-h"></i> Radius: ' + item.name;
      }
    },
    scope: {
      vm: "scopeId",
      ui: "Scope",
      display: "scopeName",
      displayClasses: function() {
        return "fa fa-fw fa-spin fa-spinner";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-spin fa-spinner"></i> Scope: ' + item.name;
      }
    },

    includeUnavailable: {
      vm: "includeUnavailable",
      ui: "Include Unavailable",
      display: "includeUnavailable",
      displayClasses: function() {
        return "fa fa-fw fa-ban";
      }
    },
    frequency: {
      vm: "frequency",
      ui: "Frequency",
      display: "frequency",
      displayClasses: function() {
        return "fas fa-fw fa-filter";
      }
    },
    typeOpen: {
      vm: "typeOpen",
      ui: "Open Auths",
      display: "typeOpen",
      displayClasses: function() {
        return "fas fa-fw fa-filter";
      }
    },
    typeClosed: {
      vm: "typeClosed",
      ui: "Closed Auths",
      display: "typeClosed",
      displayClasses: function() {
        return "fas fa-fw fa-filter";
      }
    },
    typeNone: {
      vm: "typeNone",
      ui: "No Auths",
      display: "typeNone",
      displayClasses: function() {
        return "fas fa-fw fa-filter";
      }
    },

    expires: {
      vm: "expires",
      ui: "Expires",
      display: "expiresDisplay",
      displayClasses: function() {
        return "fas fa-fw fa-filter";
      }
    },
    effective: {
      vm: "effective",
      ui: "Effective",
      display: "effectiveDisplay",
      displayNotMapped: true,
      displayClasses: function() {
        return "fas fa-fw fa-filter";
      }
    },
    effectiveExpiresPeriod: { vm: "period", display: "period", displayNotMapped: true, displayClasses: $.noop },

    form: {
      vm: "formId",
      ui: "Form",
      display: "formName",
      displayClasses: function() {
        return "far fa-fw fa-clipboard";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-clipboard"></i> ' + item.name;
      }
    },

    sort: {
      vm: "sort",
      ui: "Sort",
      display: "sortName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },

    goal: {
      vm: "id",
      ui: "Goal",
      display: "id",
      displayClasses: function() {
        return "fa fa-fw fa-bullseye";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-bullseye"></i> ' + item.name;
      }
    },
    goalType: {
      vm: "typeId",
      ui: "Goal Type",
      display: "type",
      displayClasses: function() {
        return "fa fa-fw fa-graduation-cap";
      },
      option: function(item) {
        return '<i class="fa fa-fw fa-graduation-cap"></i> ' + item.name;
      }
    },
    goalDomain: {
      vm: "domainId",
      ui: "Domain",
      display: "domain",
      displayClasses: function() {
        return "far fa-fw fa-clipboard";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-clipboard"></i> ' + item.name;
      }
    },
    goalRecord: {
      vm: "recordId",
      ui: "Record",
      display: "record",
      displayClasses: function() {
        return "far fa-fw fa-clipboard";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-clipboard"></i> ' + item.name;
      }
    },
    goalDiscipline: {
      vm: "disciplineId",
      ui: "Discipline",
      display: "discipline",
      displayClasses: function() {
        return "far fa-fw fa-clipboard";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-clipboard"></i> ' + item.name;
      }
    },
    goalStatus: {
      vm: "goalStatus",
      ui: "Goal Status",
      display: "goalStatusName",
      displayClasses: function() {
        return "far fa-fw fa-clipboard";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-clipboard"></i> ' + item.name;
      }
    },
    goalResponsibility: {
      vm: "responsibilityId",
      ui: "Responsibility",
      display: "responsibility",
      displayClasses: function() {
        return "far fa-fw fa-clipboard";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-clipboard"></i> ' + item.name;
      }
    },
    goalAssessment: {
      vm: "assessmentId",
      ui: "Assessment",
      display: "assessment",
      displayClasses: function() {
        return "far fa-fw fa-clipboard";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-clipboard"></i> ' + item.name;
      }
    },
    goalAvgIntervention: {
      vm: "goalAvgIntervention",
      ui: "Average intervention",
      display: "goalAvgIntervention",
      displayClasses: function() {
        return "far fa-fw fa-clipboard";
      }
    },
    goalScoreRange: {
      vm: "goalScoreRange",
      ui: "Score",
      display: "goalScoreRangeName",
      displayClasses: function() {
        return "far fa-fw fa-clipboard";
      }
    },
    goalDuration: {
      vm: "goalDuration",
      ui: "Duration",
      display: "duration",
      displayClasses: function() {
        return "far fa-fw fa-clipboard";
      }
    },
    goalTrend: {
      vm: "goalTrend",
      ui: "Trend",
      display: "trend",
      displayClasses: function() {
        return "far fa-fw fa-clipboard";
      }
    },
    goalMethod: {
      vm: "goalMethod",
      ui: "Method",
      display: "method",
      displayClasses: function() {
        return "far fa-fw fa-clipboard";
      }
    },
    goalPhase: {
      vm: "goalPhase",
      ui: "Phase",
      display: "phase",
      displayClasses: function() {
        return "far fa-fw fa-clipboard";
      }
    },
    goalActivity: {
      vm: "goalActivity",
      ui: "Activity",
      display: "activity",
      displayClasses: function() {
        return "far fa-fw fa-clipboard";
      }
    },
    checknumber: {
      vm: "checknumber",
      ui: "Check Number",
      display: "checknumber",
      displayClasses: function() {
        return "far fa-fw fa-money-bill-alt";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-money-bill-alt"></i> Check: ' + item.name;
      }
    },
    payment: {
      vm: "paymentId",
      ui: "Payment",
      display: "paymentName",
      displayClasses: function() {
        return "far fa-fw fa-money-bill-alt";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-money-bill-alt"></i> Payment: ' + item.name;
      }
    },
    dateType: {
      vm: "dateType",
      ui: "Date Type",
      display: "dateTypeDisplay",
      displayNotMapped: true,
      displayClasses: function() {
        return "far fa-fw fa-clipboard";
      },
      option: function(item) {
        return '<i class="far fa-fw fa-clipboard"></i> ' + item.name;
      }
    },
    isNew: {
      vm: "isNew",
      ui: "New",
      display: "isNewName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    forceDateRange: {
      vm: "forceDateRange",
      ui: "Range",
      display: "forceDateRangeName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },

    // these two are in schedule as well
    providerStatus: {
      vm: "providerStatus",
      ui: "Employee Status",
      display: "providerStatusName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    clientStatus: {
      vm: "clientStatus",
      ui: "Client Status",
      display: "clientStatusName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },

    originFrom: {
      vm: "originFrom",
      ui: "Origin",
      display: "originFromName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    originBy: {
      vm: "originBy",
      ui: "Created by",
      display: "originByName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    isDuplicate: {
      vm: "isDuplicate",
      ui: "Duplicate",
      display: "isDuplicateName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    isOverlapping: {
      vm: "isOverlapping",
      ui: "Overlapping",
      display: "isOverlappingName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    mismatchScheduleCode: {
      vm: "mismatchScheduleCode",
      ui: "Schedule Code Mismatch",
      display: "mismatchScheduleCodeName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    mismatchScheduleAuth: {
      vm: "mismatchScheduleAuth",
      ui: "Schedule Auth Mismatch",
      display: "mismatchScheduleAuthName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    mismatchScheduleDuration: {
      vm: "mismatchScheduleDuration",
      ui: "Schedule Duration Mismatch",
      display: "mismatchScheduleDurationName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    mismatchScheduleStartTime: {
      vm: "mismatchScheduleStartTime",
      ui: "Schedule Start Time Mismatch",
      display: "mismatchScheduleStartTimeName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },

    // these are local for the new resource & resource template module
    signedByAll: {
      vm: "signedByAll",
      ui: "Signed by All",
      display: "signedByAllName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    unsigned: {
      vm: "unsigned",
      ui: "Un-signed",
      display: "unsigned",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    signedByContact: {
      vm: "signedByContactId",
      ui: "Signed by",
      display: "signedByContactName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return "Signed by " + item.name;
      }
    },
    unsignedByContact: {
      vm: "unsignedByContactId",
      ui: "Not Yet Signed by",
      display: "unsignedByContactName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return "Not yet signed by by " + item.name;
      }
    },
    messageId: {
      vm: "messageId",
      ui: "Message",
      display: "messageName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    templateId: {
      vm: "templateId",
      ui: "Template",
      display: "templateName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    templateGroupId: {
      vm: "templateGroupId",
      ui: "Folder",
      display: "templateGroupName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    templateTypeId: {
      vm: "templateTypeId",
      ui: "Type",
      display: "templateTypeName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    fileStatus: {
      vm: "fileStatus",
      ui: "Status",
      display: "fileStatusName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    contentLength: {
      vm: "contentLength",
      ui: "Size",
      display: "contentLengthName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    versions: {
      vm: "versions",
      ui: "Versions",
      display: "versionsName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    creationDate: {
      vm: "creationDate",
      ui: "Added",
      display: "creationDateName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    effectiveDate: {
      vm: "effectiveDate",
      ui: "Effective",
      display: "effectiveDateName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    expirationDate: {
      vm: "expirationDate",
      ui: "Expires",
      display: "expirationDateName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    originModule: {
      vm: "originModule",
      ui: "Added via",
      display: "originModuleName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    originAction: {
      vm: "originAction",
      ui: "Type",
      display: "originActionName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    authStartDate: {
      vm: "authStartDate",
      ui: "Auth Start",
      display: "authStartDateName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    authEndDate: {
      vm: "authEndDate",
      ui: "Auth Start",
      display: "authEndDateName",
      displayClasses: function() {
        return "";
      },
      option: function(item) {
        return item.name;
      }
    },
    isEarlyConversion: {
      vm: "isEarlyConversion",
      ui: "Early Conversion",
      display: "isEarlyConversionName",
      displayClasses: function () {
        return "";
      },
      option: function (item) {
        return item.name;
      }
    }
  };

  function registerAdvSearchOptions(options) {
    Object.keys(options).forEach(key => {
      if (typeof advancedSearchResultsMap[key] !== "undefined") {
        throw `Error, ${key} already exists, and cannot be re-registered`;
      }
      advancedSearchResultsMap[key] = options[key];
    });
  }

  function unRegisterAdvSearchOptions(options) {
    Object.keys(options).forEach(key => delete advancedSearchResultsMap[key]);
  }

  var ALL_DATES_VALUE = moment().add(2, "years");

  function getFilterSuppressionMap(suppressFromFilterBar) {
    let map = new Map();
    if (suppressFromFilterBar) {
      suppressFromFilterBar.forEach(entry => {
        if (typeof entry === "string") {
          map.set(entry, "always");
        } else {
          if (!entry.key || !entry.flag) {
            throw new Error("'suppressFromFilterBar' must be an array containing strings or objects with 'key' and 'flag' properties");
          }
          if (!/^(?:suppressInclude|suppressExclude|always)$/.test(entry.flag)) {
            throw new Error("'suppressFromFilterBar' object's 'flag' property must be 'always', 'suppressInclude', or 'suppressExclude'");
          }
          map.set(entry.key, entry.flag);
        }
      });
    }
    return map;
  }

  function getSearchVmExtensions(localConfig) {
    var defaultValues = localConfig.defaultValues || {},
      hashManager = new cr.hashUtil.hashManager(),
      suppressMap = getFilterSuppressionMap(localConfig.suppressFromFilterBar);

    function page() {
      var hashObj = hashManager.parseHashTag(window.location.hash);

      if (this.page() > 1) {
        hashObj.setValue("page", this.page());
      } else {
        hashObj.removeValue("page", this.page());
      }
      setHashAndGo.call(this, hashObj, { adding: "page" });
    }

    function executeHashObj(hashObj, changingObj) {
      hashObj.removeValue("page");
      setHashAndGo.call(this, hashObj, changingObj);
    }

    function setHashAndGo(hashObj, changingObj) {
      if (localConfig.addStartDate || localConfig.addStartAndEndDates) {
        hashObj.setValue("startdate", this.startDateDisplay());
      }
      if (localConfig.addStartAndEndDates) {
        hashObj.setValue("enddate", this.endDateDisplay());
      }

      //This keeps options off the hash - useful if you want a searchVm *helper* that doesn't mess with the hash
      //NOTE - this does not work with label collections at the moment, mainly because it's not needed, and probably
      //never will be
      if (localConfig.withoutHashSync) {
        let parameters = hashObj.getParameters();
        if (changingObj.adding) {
          let props = changingObj.adding.split(",");
          props.forEach(prop => this.setSearchVmProperty(prop, parameters[prop]));
        }
        if (changingObj.removing) {
          let props = changingObj.removing.split(",");
          props.forEach(prop => this.setSearchVmProperty(prop, ""));
        }
        this.advancedSearchMessageBus.sendMessage("search-values-updated-out-of-hash-cycle", {});
        return;
      }

      typeof this.onSearchChanging === "function" && this.onSearchChanging(hashObj, changingObj);
      var newHash = hashManager.createHashTag(hashObj),
        currentHash = window.location.hash;
      if (currentHash[0] === "#") {
        currentHash = currentHash.substr(1);
      }

      if (currentHash === newHash) {
        cr.router.onHashChanged();
        if (typeof this.onHashChangeSwallowed === "function") {
          this.onHashChangeSwallowed();
        }
      } else {
        window.location.hash = newHash;
      }
    }

    //dates used for date range syncing
    var today,
      yesterday,
      last7,
      last30,
      last45,
      last60,
      last90,
      daysago7,
      daysago14,
      daysago30,
      daysago45,
      daysago60,
      daysago90,
      r60,
      r60Start,
      r90,
      r90Start,
      r120,
      r120Start,
      thisMonthStart,
      thisMonthEnd,
      lastMonthStart,
      lastMonthEnd,
      nextMonthStart,
      nextMonthEnd,
      thisWeekStart,
      thisWeekEnd,
      nextWeekStart,
      nextWeekEnd;

    //I could have made these constants, but I'm paranoid, and afraid of a user opening our app at 11:55pm
    //and crossing the midnight boundary.
    function setDateConstants() {
      today = moment().format("MM-DD-YYYY");
      yesterday = moment()
        .add(-1, "days")
        .format("MM-DD-YYYY");
      last7 = moment()
        .add(-6, "days")
        .format("MM-DD-YYYY");
      last30 = moment()
        .add(-30, "day")
        .format("MM-DD-YYYY");
      last45 = moment()
        .add(-45, "day")
        .format("MM-DD-YYYY");
      last60 = moment()
        .add(-60, "day")
        .format("MM-DD-YYYY");
      last90 = moment()
        .add(-90, "day")
        .format("MM-DD-YYYY");
      daysago7 = moment()
        .add(-6, "day")
        .format("MM-DD-YYYY");
      daysago14 = moment()
        .add(-13, "day")
        .format("MM-DD-YYYY");
      daysago30 = moment()
        .add(-29, "day")
        .format("MM-DD-YYYY");
      daysago45 = moment()
        .add(-44, "day")
        .format("MM-DD-YYYY");
      daysago60 = moment()
        .add(-59, "day")
        .format("MM-DD-YYYY");
      daysago90 = moment()
        .add(-89, "day")
        .format("MM-DD-YYYY");
      r60 = moment()
        .add(-31, "day")
        .format("MM-DD-YYYY");
      r60Start = moment()
        .add(-60, "day")
        .format("MM-DD-YYYY");
      r90 = moment()
        .add(-61, "day")
        .format("MM-DD-YYYY");
      r90Start = moment()
        .add(-90, "day")
        .format("MM-DD-YYYY");
      r120 = moment()
        .add(-91, "day")
        .format("MM-DD-YYYY");
      r120Start = moment()
        .add(-120, "day")
        .format("MM-DD-YYYY");
      thisMonthStart = moment()
        .startOf("month")
        .format("MM-DD-YYYY");
      thisMonthEnd = moment()
        .endOf("month")
        .format("MM-DD-YYYY");
      lastMonthStart = moment()
        .add(-1, "months")
        .startOf("month")
        .format("MM-DD-YYYY");
      lastMonthEnd = moment()
        .add(-1, "months")
        .endOf("month")
        .format("MM-DD-YYYY");
      nextMonthStart = moment()
        .add(1, "months")
        .startOf("month")
        .format("MM-DD-YYYY");
      nextMonthEnd = moment()
        .add(1, "months")
        .endOf("month")
        .format("MM-DD-YYYY");

      thisWeekStart = moment()
        .startOf("week")
        .add(1, "days")
        .format("MM-DD-YYYY");
      thisWeekEnd = moment()
        .endOf("week")
        .add(1, "days")
        .format("MM-DD-YYYY");
      nextWeekStart = moment()
        .add(1, "weeks")
        .startOf("week")
        .add(1, "days")
        .format("MM-DD-YYYY");
      nextWeekEnd = moment()
        .add(1, "weeks")
        .endOf("week")
        .add(1, "days")
        .format("MM-DD-YYYY");
    }

    // translate the task period filter to a proper date and attach it to the request object we're looking to send
    function addTaskPeriod(req) {
      if (this.tasksFilter()) {
        if (this.tasksFilter() == "overdue") {
          req.tasksPeriod = "overdue";
          req.tasksDate = moment().format("MM-DD-YYYY");
        } else if (this.tasksFilter() == "today") {
          req.tasksPeriod = "day";
          req.tasksDate = moment().format("MM-DD-YYYY");
        } else if (this.tasksFilter() == "tomorrow") {
          req.tasksPeriod = "day";
          req.tasksDate = moment()
            .add(1, "days")
            .format("MM-DD-YYYY");
        } else if (this.tasksFilter() == "thisweek") {
          req.tasksPeriod = "week";
          req.tasksDate = moment()
            .startOf("week")
            .add(1, "days")
            .format("MM-DD-YYYY");
        } else if (this.tasksFilter() == "nextweek") {
          req.tasksPeriod = "week";
          req.tasksDate = moment()
            .add(1, "weeks")
            .startOf("week")
            .add(1, "days")
            .format("MM-DD-YYYY");
        }
      }
      return req;
    }

    return {
      _syncingPageSize: false,
      resetting: ko.observable(false),
      searchPropertyIsActive: function(prop, suppressFlag) {
        if (this._collectionArr.indexOf(prop) > -1) {
          let collection = this[prop + "Collection"]();
          if (suppressFlag == "suppressInclude") {
            collection = collection.filter(e => !e.isIncluded());
          } else if (suppressFlag == "suppressExclude") {
            collection = collection.filter(e => e.isIncluded());
          }
          return collection.length;
        } else {
          return typeof defaultValues[prop] === "undefined" ? this[prop]() : this[prop]() != defaultValues[prop];
        }
      },
      pageUp: function() {
        this.page(+this.page() + 1);
        page.call(this);
      },
      pageDown: function() {
        this.page(+this.page() - 1);
        page.call(this);
      },
      setPageSize: function(pageSize) {
        this.pageSize(pageSize);
        this.page(1);
        page.call(this);
      },
      handlePageSizeOnHashChanging: function(defaultPageSize, hashObj) {
        if (this.pageSize() == defaultPageSize) {
          hashObj.removeValue("pageSize");
        } else {
          hashObj.setValue("pageSize", this.pageSize());
        }
      },
      fireOffSearch: function() {
        var hashObj = hashManager.parseHashTag(window.location.hash);

        if (this.startDate && this.startDate()) {
          hashObj.removeValue("all-dates");
        }
        executeHashObj.call(this, hashObj, {});
      },
      setFilterItem: function(prop, val) {
        var hashObj = hashManager.parseHashTag(window.location.hash);

        if (this.restrictToOneFilter) {
          this.resetting(true);
          this.resetFiltersOnObject(hashObj);
        }

        if (val == null || val == defaultValues[prop]) {
          hashObj.removeValue(prop, val);
        } else {
          hashObj.setValue(prop, val);
        }
        executeHashObj.call(this, hashObj, { adding: prop });
      },
      toggleFilterCollectionItem: function(prop, val) {
        val = "" + val;
        var hashObj = hashManager.parseHashTag(window.location.hash),
          parameters = hashObj.getParameters();

        var included = (parameters[prop + "Included"] || "").split("-"),
          excluded = (parameters[prop + "Excluded"] || "").split("-");

        if (!included[0]) included.splice(0, 1);
        if (!excluded[0]) excluded.splice(0, 1);

        if (included.indexOf(val) >= 0) {
          included.splice(included.indexOf(val), 1);
          excluded.push(val);
        } else if (excluded.indexOf(val) >= 0) {
          excluded.splice(excluded.indexOf(val), 1);
          included.push(val);
        } else {
          included.push(val);
        }

        if (included.length) {
          hashObj.setValue(prop + "Included", included.join("-"));
        } else {
          hashObj.removeValue(prop + "Included");
        }

        if (excluded.length) {
          hashObj.setValue(prop + "Excluded", excluded.join("-"));
        } else {
          hashObj.removeValue(prop + "Excluded");
        }

        executeHashObj.call(this, hashObj, { adding: prop });
      },
      includeFilterCollectionItem: function (prop, val) {
        const filter = val?.toString();
        
        if (!filter || !prop) {
          return;
        }
        
        let hashObj = hashManager.parseHashTag(window.location.hash),
          parameters = hashObj.getParameters(),
          collectionName = `${prop}Included`,
          includedValues = parameters[collectionName]?.split("-") ?? [],
          excludedValues = parameters[`${prop}Excluded`]?.split("-") ?? [];
        
        if (includedValues.includes(filter)) {
          return;
        }

        if (excludedValues.includes(filter)) {
          const removedNewValue = excludedValues.filter(x => x !== filter);
          if (removedNewValue.some(s => s)) {
            hashObj.setValue(`${prop}Excluded`, removedNewValue.join("-"));
          } else {
            hashObj.removeValue(`${prop}Excluded`);
          }
        }

        includedValues.push(filter);
        hashObj.setValue(collectionName, includedValues.join("-"));

        executeHashObj.call(this, hashObj, { adding: prop });
      },
      excludeFilterCollectionItem: function (prop, val) {
        const filter = val?.toString();
        
        if (!filter || !prop) {
          return;
        }
        
        let hashObj = hashManager.parseHashTag(window.location.hash),
          parameters = hashObj.getParameters(),
          collectionName = `${prop}Excluded`,
          excludedValues = parameters[collectionName]?.split("-") ?? [],
          includedValues = parameters[`${prop}Included`]?.split("-") ?? [];
        
        if (excludedValues.includes(filter)) {
          return;
        }

        if (includedValues.includes(filter)) {
          const removedNewValue = includedValues.filter(x => x !== filter);
          if (removedNewValue.some(s => s)) {
            hashObj.setValue(`${prop}Included`, removedNewValue.join("-"));
          } else {
            hashObj.removeValue(`${prop}Included`);
          }
        }

        excludedValues.push(filter);
        hashObj.setValue(collectionName, excludedValues.join("-"));

        executeHashObj.call(this, hashObj, { adding: prop });
      },
      serializeFilters(config) {
        let result = {};

        Object.keys(advancedSearchResultsMap).forEach(key => {
          let packet = advancedSearchResultsMap[key];
          if (typeof result[packet.vm] === "undefined") {
            //some vm properties are specified twice, under different headings
            if (this._collectionArr.indexOf(packet.vm) >= 0) {
              result[packet.vm + "Included"] = this[packet.vm + "Collection"]()
                .filter(l => l.isIncluded())
                .map(l => l.id())
                .join(",");
              result[packet.vm + "Excluded"] = this[packet.vm + "Collection"]()
                .filter(l => !l.isIncluded())
                .map(l => l.id())
                .join(",");

              if (!result[packet.vm + "Included"]) {
                delete result[packet.vm + "Included"];
              }

              if (!result[packet.vm + "Excluded"]) {
                delete result[packet.vm + "Excluded"];
              }
            } else {
              if (this.searchPropertyIsActive(packet.vm)) {
                result[packet.vm] = this[packet.vm]();
              }
            }
          }

          if (config.selectableColumns) {
            let selectedColumns = config
              .selectableColumns()
              .filter(c => c.active())
              .map(c => c.cssClass);
            result.selectedColumns = selectedColumns.join(",");
          }
        });

        if (config.addDateRange) {
          result.dateRange = this.translateDateRange();
          if (!result.dateRange || config.forceCustom == 1) {
            result.dateRange = `custom:${moment(this.startDate()).format("MM-DD-YYYY")}|${moment(this.endDate()).format("MM-DD-YYYY")}`;
          }
          if (config.forceCustom == -1) {
            delete result.dateRange;
          }
        }

        (config.datesWithPresets || []).filter(p => p.currentValue()).forEach(({ prop: name, forceCustom, currentValue }) => {
          if (!+forceCustom()) {
            // 0 or 1 stored as strings to ease ko bindings
            var value = this.translateDateValue(currentValue());
          }
          result[name] = value || `custom:${moment(result[name]).format("MM-DD-YYYY")}`;
        });

        return result;
      },
      deSerializeFilters(filters, config) {
        let filtersObj = JSON.parse(filters);
        delete filtersObj.selectedColumns;
        delete filtersObj.agGridColumns;
        delete filtersObj.agGridFilters;

        if (filtersObj.dateRange) {
          let [startDate, endDate] = this.translateDateRangeBack(filtersObj.dateRange, config.defaultStartDate, config.defaultEndDate);

          delete filtersObj.dateRange;
          //start and end dates require special treatment :-|
          this.startDate(startDate.replace(/-/g, "/"));
          this.endDate(endDate.replace(/-/g, "/"));
        }

        this.setFilterItems(filtersObj, config);
      },
      setFilterItems: function(mapObject, config) {
        var hashObj = hashManager.parseHashTag(window.location.hash),
          added = [];

        if (this.anyFiltersActive()) {
          this.resetting(true);
        }
        this.resetFiltersOnObject(hashObj);

        this._collectionArr.forEach(prop => {
          let incName = prop + "Included",
            exName = prop + "Excluded";

          if (mapObject[incName]) {
            hashObj.setValue(incName, mapObject[incName].replace(/,/g, "-"));
            delete mapObject[incName];
          }

          if (mapObject[exName]) {
            hashObj.setValue(exName, mapObject[exName].replace(/,/g, "-"));
            delete mapObject[exName];
          }
        });

        for (var prop in mapObject) {
          if (mapObject.hasOwnProperty(prop) && mapObject[prop] !== "" && mapObject[prop] != null) {
            added.push(prop);
            hashObj.setValue(prop, mapObject[prop]);
          }
        }

        let dateValues = config.dateValues || [];
        dateValues.forEach(name => {
          let savedValue = mapObject[name];
          if (!savedValue) return;

          if (/^custom:/.test(savedValue)) {
            hashObj.setValue(name, savedValue.split("custom:")[1]);
          } else {
            hashObj.setValue(name, this.translateDateValueBack(savedValue));
          }
        });

        executeHashObj.call(this, hashObj, { adding: added.join(",") });
      },
      clearFilterItem: function(vm, e) {
        var hashObj = hashManager.parseHashTag(window.location.hash),
          prop = $(e.target).attr("data-vmProp");

        if (!prop) {
          prop = $(e.target)
            .closest("[data-vmProp]")
            .attr("data-vmProp");
        }
        hashObj.removeValue(prop);
        executeHashObj.call(this, hashObj, { removing: prop });
      },
      toggleOffItem(vm, e) {
        var hashObj = hashManager.parseHashTag(window.location.hash),
          prop = $(e.target).attr("data-vmProp");

        if (!prop) {
          prop = $(e.target)
            .closest("[data-vmProp]")
            .attr("data-vmProp");
        }
        hashObj.setValue(prop, ko.unwrap(vm[prop]) == 1 ? "0" : -1 * ko.unwrap(vm[prop]));
        executeHashObj.call(this, hashObj, { removing: prop });
      },
      toggleOnItem(vm, e) {
        var hashObj = hashManager.parseHashTag(window.location.hash),
          prop = $(e.target).attr("data-vmProp");

        if (!prop) {
          prop = $(e.target)
            .closest("[data-vmProp]")
            .attr("data-vmProp");
        }
        hashObj.setValue(prop, ko.unwrap(vm[prop]) == 0 ? "1" : -1 * ko.unwrap(vm[prop]));
        executeHashObj.call(this, hashObj, { removing: prop });
      },
      includeItem: function(item, e) {
        item.include();
        this.updateHashCollection($(e.target).attr("data-prop"));
      },
      excludeItem: function(item, e) {
        item.exclude();
        this.updateHashCollection($(e.target).attr("data-prop"));
      },
      removeFromFilterCollection: function(item, e) {
        const prop = $(e.target).attr("data-prop"),
          collection = `${prop}Collection`;

        if (typeof this[collection] !== "function") {
          console.error(`cannot update filter, ${prop} is not a filter collection`);
          return;
        }

        this[collection].remove(item);
        this.updateHashCollection(prop);
      },
      removeItemFromFilterCollection: function(originalPropertyName, itemId) {
        var coll = this[originalPropertyName + "Collection"],
          toRemove = coll().find(packet => packet.id() == itemId);

        if (toRemove) {
          coll.remove(toRemove);
          this.updateHashCollection(originalPropertyName);
        }
      },
      resetFiltersOnObject: function(hashObj) {
        for (var key in advancedSearchResultsMap) {
          var packet = advancedSearchResultsMap[key];
          if (this.searchPropertyIsActive(packet.vm)) {
            this.resetFilter(packet);
            if (hashObj) {
              if (this._collectionArr.indexOf(packet.vm) > -1) {
                hashObj.removeValue(`${packet.vm}Included`);
                hashObj.removeValue(`${packet.vm}Excluded`);
              } else {
                hashObj.removeValue(packet.vm);
              }
            }
          }
        }
      },
      removeAllFilters: function() {
        var hashObj = hashManager.parseHashTag(window.location.hash);
        this.resetFiltersOnObject(hashObj);
        this.isDirty(true);
        executeHashObj.call(this, hashObj, { removing: -1 });
      },
      resetFilter: function(packet) {
        var prop = packet.vm;

        if (this._collectionArr.indexOf(prop) > -1) {
          this[prop + "Collection"].removeAll();
        } else {
          if (typeof defaultValues[prop] === "undefined") this[prop]("");
          else this[prop](defaultValues[prop]);

          if (this[packet.display]) {
            this[packet.display]("");
          }
        }
      },
      getRequestObject: function() {
        var result = ko.toJS(this);
        this._collectionArr.forEach(prop => {
          result[prop + "s"] = this.getCollectionRequestValue(prop);

          let currentValues = this[prop + "Collection"]();
          result[prop + "Included"] = currentValues
            .filter(o => o.isIncluded())
            .map(o => o.id())
            .join(",");
          result[prop + "Excluded"] = currentValues
            .filter(o => !o.isIncluded())
            .map(o => o.id())
            .join(",");
        });
        addTaskPeriod.call(this, result);
        delete result._advancedSearchConfig;

        if (
          typeof this.startDate === "function" &&
          typeof this.endDate === "function" &&
          this.startDate &&
          moment(this.startDate()).isSame(ALL_DATES_VALUE, "day") &&
          moment(this.endDate()).isSame(ALL_DATES_VALUE, "day")
        ) {
          delete result.startDate;
          delete result.endDate;
        }

        result.startDate = moment(result.startDate).format("YYYY-MM-DD");
        result.endDate = moment(result.endDate).format("YYYY-MM-DD");

        return result;
      },
      getCleanRequestObject: function() {
        var result = {};
        this._collectionArr.forEach(prop => {
          let allValues = this.getCollectionRequestValue(prop);
          if (allValues) {
            result[prop + "s"] = allValues;
          }

          let currentValues = this[prop + "Collection"]();
          let included = currentValues
            .filter(o => o.isIncluded())
            .map(o => o.id())
            .join(",");
          if (included) {
            result[prop + "Included"] = included;
          }
          let excluded = currentValues
            .filter(o => !o.isIncluded())
            .map(o => o.id())
            .join(",");
          if (excluded) {
            result[prop + "Excluded"] = excluded;
          }
        });
        addTaskPeriod.call(this, result);

        if (this.startDate) {
          result.startDate = moment(ko.unwrap(this.startDate)).format("YYYY-MM-DD");
        }

        if (this.endDate) {
          result.endDate = moment(ko.unwrap(this.endDate)).format("YYYY-MM-DD");
        }

        if (
          typeof this.startDate === "function" &&
          typeof this.endDate === "function" &&
          this.startDate &&
          moment(this.startDate()).isSame(ALL_DATES_VALUE, "day") &&
          moment(this.endDate()).isSame(ALL_DATES_VALUE, "day")
        ) {
          delete result.startDate;
          delete result.endDate;
        }

        Object.keys(advancedSearchResultsMap).forEach(k => {
          let packet = advancedSearchResultsMap[k];
          if (this._collectionArr.indexOf(packet.vm) < 0 && this.searchPropertyIsActive(packet.vm)) {
            result[packet.vm] = ko.unwrap(this[packet.vm]);
          }
        });

        let manualProperties = ["sortBy", "sort", "page", "pageSize"];
        manualProperties.forEach(prop => {
          if (this.hasOwnProperty(prop) && ko.unwrap(this[prop])) {
            result[prop] = ko.unwrap(this[prop]);
          }
        });

        return result;
      },
      getCollectionRequestValue(prop) {
        var currentValues = this[prop + "Collection"]();
        return currentValues
          .filter(o => o.isIncluded())
          .map(o => o.id())
          .concat(currentValues.filter(o => !o.isIncluded()).map(o => "-" + o.id()))
          .join(",");
      },
      anyFiltersActive: function() {
        for (var key in advancedSearchResultsMap) {
          var packet = advancedSearchResultsMap[key],
            prop = packet.vm;

          if (this.searchPropertyIsActive(prop)) {
            return true;
          }
        }
        return false;
      },
      filterBarVisible: function() {
        if (this.resetting()) return true;
        for (var key in advancedSearchResultsMap) {
          var packet = advancedSearchResultsMap[key],
            prop = packet.vm,
            suppressFlag = suppressMap.get(key);

          if (suppressFlag != "always" && this.searchPropertyIsActive(prop, suppressFlag)) {
            return true;
          }
        }
        return false;
      },
      updateHashCollection: function(rootName) {
        var hashObj = hashManager.parseHashTag(window.location.hash),
          collName = `${rootName}Collection`;

        var includeIds = this[collName]()
            .filter(function(item) {
              return item.isIncluded();
            })
            .map(function(item) {
              return item.id();
            })
            .join("-"),
          excludeIds = this[collName]()
            .filter(function(item) {
              return !item.isIncluded();
            })
            .map(function(item) {
              return item.id();
            })
            .join("-");

        if (this.restrictToOneFilter) {
          this.resetting(true);
          this.resetFiltersOnObject(hashObj);
        }

        if (includeIds) {
          hashObj.setValue(rootName + "Included", includeIds);
        } else {
          hashObj.removeValue(rootName + "Included");
        }

        if (excludeIds) {
          hashObj.setValue(rootName + "Excluded", excludeIds);
        } else {
          hashObj.removeValue(rootName + "Excluded");
        }
        this.isDirty(true);
        executeHashObj.call(this, hashObj, { updateCollection: collName });
      },
      mapSearchValuesFromResponse: function(resp) {
        for (var key in advancedSearchResultsMap) {
          var packet = advancedSearchResultsMap[key];

          if (resp[packet.vm + "s"]) {
            resp[packet.vm + "s"].forEach(item => {
              if (!(item.Name || item.name)) item.Error = true;

              if (typeof item.id !== "undefined" && item.id < 0) {
                item.id = item.id * -1;
              }
              if (typeof item.Id !== "undefined" && item.Id < 0) {
                item.Id = item.Id * -1;
              }
            });

            resp[packet.vm + "s"].forEach(function(item) {
              var existing = this[packet.vm + "Collection"]().filter(function(o) {
                return o.id() == item.id || o.id() == item.Id;
              })[0];
              if (existing) {
                existing.mapFromResponse(item);
              } else {
                var vmToUse = this.constructor.config.mappedArrays.filter(function(vmPacket) {
                  return vmPacket.name === packet.vm + "Collection";
                })[0].viewModelType;
                this[packet.vm + "Collection"].push(vmToUse.createFromResponse(item));
              }
            }, this);
          }
        }
        this.mapFromResponse(resp);
        this.isDirty(false);
        this.advancedSearchMessageBus.sendMessage("search-value-displays-updated", {});
      },
      syncWithHash: function(options) {
        options = $.extend({ skip: [] }, options || {});

        var parameters = hashManager.parseHashTag(window.location.hash).getParameters(),
          config = this.constructor.config;

        const hasStartDate = !!parameters.startdate;
        const hasEndDate = !!parameters.enddate;
        const parsedStartDate = hasStartDate ? moment(parameters.startdate.replace(/-/g, "/")) : moment(localConfig.defaultStartDate);
        const parsedEndDate = hasEndDate ? moment(parameters.enddate.replace(/-/g, "/")) : moment(localConfig.defaultEndDate);

        if (localConfig.addStartDate || localConfig.addStartAndEndDates) {
          const newStartDate =
            localConfig.addStartAndEndDates && hasEndDate && parsedEndDate.isBefore(parsedStartDate) ? parsedEndDate : parsedStartDate;

          if (parameters["all-dates"]) {
            this.startDate(ALL_DATES_VALUE);
          } else if (!CrAreDatesEqual(this.startDate(), newStartDate)) {
            this.startDate(newStartDate);
          }
        }
        if (localConfig.addStartAndEndDates) {
          const newEndDate = hasStartDate && parsedStartDate.isAfter(parsedEndDate) ? parsedStartDate : parsedEndDate;

          if (parameters["all-dates"]) {
            this.endDate(ALL_DATES_VALUE);
          } else if (!CrAreDatesEqual(this.endDate(), newEndDate)) {
            this.endDate(newEndDate);
          }
        }

        if (typeof this.page === "function" && !this._continuousPaging) {
          this.page(parameters.page || 1);
        }
        if (typeof this.pageSize === "function" && defaultValues.pageSize) {
          this._syncingPageSize = true;
          this.pageSize(+parameters.pageSize || defaultValues.pageSize);
          this._syncingPageSize = false;
        }

        this._syncingWithHash = true;
        for (var key in advancedSearchResultsMap) {
          var packet = advancedSearchResultsMap[key];

          if (this._collectionArr.indexOf(packet.vm) == -1) {
            if (options.skip.indexOf(packet.vm) >= 0) continue;

            this.setSearchVmProperty(packet.vm, parameters[packet.vm]);
          } else {
            var arrayFilter = this[packet.vm + "Collection"],
              vmToUse = config.mappedArrays.filter(function(vmPacket) {
                return vmPacket.name === packet.vm + "Collection";
              })[0].viewModelType;

            if (!parameters[packet.vm + "Included"] && !parameters[packet.vm + "Excluded"]) {
              if (arrayFilter().length) {
                arrayFilter.removeAll();
                this.isDirty(true);
              }
            } else {
              syncArrayFilter.call(this, arrayFilter, parameters[packet.vm + "Included"] || "", parameters[packet.vm + "Excluded"] || "", vmToUse);
            }
          }
        }
        this._syncingWithHash = false;

        this.advancedSearchMessageBus.sendMessage("search-values-updated", {});
        this.resetting(false);
      },
      setSearchVmProperty(vmProp, setTo) {
        var emptyValue = typeof defaultValues[vmProp] === "undefined" ? "" : defaultValues[vmProp];

        if (this[vmProp]() != (setTo || emptyValue)) {
          this[vmProp](setTo || emptyValue);
        }
      },
      translateDateRangeFriendly() {
        if (typeof this.startDate === "undefined" || typeof this.endDate === "undefined") return "";

        let result = this.translateDateRange();

        if (result == "today") return "today";
        if (result == "yesterday") return "yesterday";
        if (result == "last7") return "last 7 days";
        if (result == "last30") return "last 30 days";
        if (result == "last45") return "last 45 days";
        if (result == "r60") return "31–60 days";
        if (result == "r90") return "61–90 days";
        if (result == "r120") return "91–120 days";
        if (result == "thismonth") return "this month";
        if (result == "lastmonth") return "last month";
        if (result == "thisweek") return "this week";
        if (result == "nextweek") return "next week";
        if (result == "nextmonth") return "next month";
        return result;
      },
      translateDateRange() {
        let startDate = this.startDate(),
          endDate = this.endDate();

        setDateConstants();

        var start = moment(startDate).format("MM-DD-YYYY");
        var end = moment(endDate).format("MM-DD-YYYY");

        if (today == start && today == end) {
          return "today";
        } else if (yesterday == start && yesterday == end) {
          return "yesterday";
        } else if (today == end && last7 == start) {
          return "last7";
        } else if (today == end && last30 == start) {
          return "last30";
        } else if (today == end && last45 == start) {
          return "last45";
        } else if (start == r60Start && r60 == end) {
          return "r60";
        } else if (start == r90Start && r90 == end) {
          return "r90";
        } else if (start == r120Start && r120 == end) {
          return "r120";
        } else if (thisMonthStart == start && thisMonthEnd == end) {
          return "thismonth";
        } else if (lastMonthStart == start && lastMonthEnd == end) {
          return "lastmonth";
        } else if (nextMonthStart == start && nextMonthEnd == end) {
          return "nextmonth";
        } else if (thisWeekStart == start && thisWeekEnd == end) {
          return "thisweek";
        } else if (nextWeekStart == start && nextWeekEnd == end) {
          return "nextweek";
        } else {
          return "";
        }
      },
      translateDateRangeBack(dateRange, defaultStartDate, defaultEndDate) {
        setDateConstants();

        if (/custom/.test(dateRange)) {
          dateRange = dateRange.replace("custom:", "");
          return dateRange.split("|");
        } else {
          const translations = {
            today: [today, today],
            yesterday: [yesterday, yesterday],
            last7: [last7, today],
            last30: [last30, today],
            last45: [last45, today],
            r60: [r60Start, r60],
            r90: [r90Start, r90],
            r120: [r120Start, r120],
            thismonth: [thisMonthStart, thisMonthEnd],
            lastmonth: [lastMonthStart, lastMonthEnd],
            nextmonth: [nextMonthStart, nextMonthEnd],
            thisweek: [thisWeekStart, thisWeekEnd],
            nextweek: [nextWeekStart, nextWeekEnd]
          };

          return translations[dateRange] || [defaultStartDate, defaultEndDate];
        }
      },
      translateDateValueFriendly(dateValue) {
        let value = this.translateDateValue(dateValue);

        if (value == "today") return "today";
        else if (value == "yesterday") return "yesterday";
        else if (value == "daysago7") return "7 days ago";
        else if (value == "daysago14") return "14 days ago";
        else if (value == "daysago30") return "30 days ago";
        else if (value == "daysago45") return "45 days ago";
        else if (value == "daysago60") return "60 days ago";
        else if (value == "daysago90") return "90 days ago";

        return "";
      },
      translateDateValue(value) {
        value = moment(value).format("MM-DD-YYYY");
        setDateConstants();

        if (value == today) return "today";
        else if (value == yesterday) return "yesterday";
        else if (value == daysago7) return "daysago7";
        else if (value == daysago14) return "daysago14";
        else if (value == daysago30) return "daysago30";
        else if (value == daysago45) return "daysago45";
        else if (value == daysago60) return "daysago60";
        else if (value == daysago90) return "daysago90";

        return "";
      },
      translateDateValueBack(value) {
        const translations = {
          today,
          yesterday,
          daysago7,
          daysago14,
          daysago30,
          daysago45,
          daysago60,
          daysago90
        };

        return translations[value];
      }
    };
  }

  var singleItemFilterBarTemplate =
    '<div class="inline-block">' +
    '<span class="label flex no-padding margin-xs-right relative" data-bind="visible: #{vm}">' +
    '<i class="#{displayClasses}"></i>' +
    "<em class=\"filter\" data-bind=\"#{binding}: '#{ui}: ' + (ko.unwrap(#{display}) || '<loading>')\"></em>" +
    '<a title="Remove filter" data-click="clearFilterItem" data-vmProp="#{vmRaw}" #{hidden}>x</a> ' +
    "</span>" +
    "</div>";

  var toggleableItemTemplate =
    '<span data-bind="visible: #{vm}">' +
    '<div class="inline-block">' +
    '<span class="label flex no-padding margin-xs-right relative">' +
    '<i data-bind="visible: !ko.unwrap(#{display})" class="fa fa-spin fa-spinner"/>' +
    "<em class=\"filter\" title=\"#{ui}\" data-bind=\"text: '#{ui}: ' + (ko.unwrap(#{vm}) > 0 ? '' : 'Not ') + (ko.unwrap(#{display}) || '<loading>')\"></em>" +
    "<!-- start-toggle-include-exclude -->" +
    '<a title="Switch include vs. exclude" class="dropdown-toggle" data-toggle="dropdown"><b class="caret no-margin"></b></a>' +
    '<ul class="dropdown-menu">' +
    '<li><a data-click="toggleOffItem" data-vmProp="#{vmRaw}" data-bind="visible: ko.unwrap(#{vm}) >= 1">Switch to exclude</a></li>' +
    '<li><a data-click="toggleOnItem" data-vmProp="#{vmRaw}" data-bind="visible: ko.unwrap(#{vm}) <= 0">Switch to include</a></li>' +
    "</ul>" +
    "<!-- end-toggle-include-exclude -->" +
    '<a class="remove" title="Remove filter" data-click="clearFilterItem" data-vmProp="#{vmRaw}">x</a> ' +
    "</span>" +
    "</div>" +
    "</span>";

  var multiSearchItemFilterBarTemplate =
    '<!-- ko foreach: #{collection} -->' +
      "#{filter-start}" +
        '<div class="inline-block">' +
          '<span class="label flex no-padding margin-xs-right relative">' +
            '<i title="#{ui}" class="#{displayClasses}"></i>' +
            '<i data-bind="visible: !name() && !error()" class="fa fa-spin fa-spinner"/>' +
            "<em class=\"filter\" title=\"#{ui}\" data-bind=\"visible: name() || error(), text: error() ? (isIncluded() ? '' : 'exclude - ') + '<Id ' + id() + ' is invalid>' : (isIncluded() ? '' : 'exclude ') + '&quot;' + name() + '&quot;'\"></em>" +
            "<!-- start-toggle-include-exclude -->" +
            '<a data-bind="visible: !$data.nonExcludable" title="Switch include vs. exclude" class="dropdown-toggle" data-toggle="dropdown"><b class="caret no-margin"></b></a>' +
              '<ul data-bind="visible: !$data.nonExcludable" class="dropdown-menu">' +
                '<li><a data-click="excludeItem" data-prop="#{vm}" data-bind="visible: isIncluded">Switch to exclude</a></li>' +
                '<li><a data-click="includeItem" data-prop="#{vm}" data-bind="visible: !isIncluded()">Switch to include</a></li>' +
              "</ul>" +
            "<!-- end-toggle-include-exclude -->" +
            '<a class="remove" title="Remove filter" data-click="removeFromFilterCollection" data-prop="#{vm}">x</a> ' +
          "</span>" +
        "</div>" +
      "#{filter-end}" +
    "<!-- /ko -->";
  
    var noToggleMultiSearchItemFilterBarTemplate =
    `<!-- ko foreach: #{collection} -->
        #{filter-start}
          <div class="inline-block">
            <span class="label flex no-padding margin-xs-right relative">
              <i title="#{ui}" class="#{displayClasses}"></i>
              <i data-bind="visible: !name() && !error()" class="fa fa-spin fa-spinner"/>
              <em class="filter" title="#{ui}" data-bind="visible: name() || error(), text: error() ? (isIncluded() ? 'Yes' : 'No') + '<Id ' + id() + ' is invalid>' :  name() + (isIncluded() ? ': Yes' : ': No') "></em>
              <a class="remove" title="Remove filter" data-click="removeFromFilterCollection" data-prop="#{vm}">x</a>
            </span>
          </div>
        #{filter-end}
     <!-- /ko -->`;
  
  var multiFilterStartMap = {
    suppressInclude: "<!-- ko if: !isIncluded() -->",
    suppressExclude: "<!-- ko if: isIncluded -->"
  };
  var multiFilterEndMap = {
    suppressInclude: "<!-- /ko -->",
    suppressExclude: "<!-- /ko -->"
  };

  function getSearchHtml(collectionArr, localConfig) {
    var singles = [],
      multi = [],
      defaultValues = localConfig.defaultValues || {},
      stickyFilters = localConfig.stickyFilters || [],
      suppressMap = getFilterSuppressionMap(localConfig.suppressFromFilterBar);

    $.each(advancedSearchResultsMap, function(key, packet) {
      if (key == "textActive") return;
      let suppressFlag = suppressMap.get(key);
      if (suppressFlag == "always") return;
      if (collectionArr.indexOf(packet.vm) === -1) {
        singles.push(getSingleFilterItemContent(localConfig, packet, suppressFlag));
      } else {
        multi.push(getMultiFilterItemContent(localConfig, packet, suppressFlag));
      }
    });

    var root = $('<div data-bind="delegatedHandler: \'click\'" class="filters search-filters"></div>');
    return root.append(singles.concat(multi));
  }

  function getLazyFilterBarContent() {
    let collectionArr = this._collectionArr,
      localConfig = this._advancedSearchConfig;

    var singleFilterItems = [],
      multiFilterItems = [],
      toggleableFilterItems = [],
      suppressMap = getFilterSuppressionMap(localConfig.suppressFromFilterBar);

    $.each(advancedSearchResultsMap, (key, packet) => {
      if (key == "textActive") return;
      let suppressFlag = suppressMap.get(key);
      if (suppressFlag == "always") return;
      if (!this.searchPropertyIsActive(packet.vm, suppressFlag)) return;

      if (packet.isToggle) {
        toggleableFilterItems.push(getToggleableFilterItemContent(localConfig, packet, suppressFlag));
      } else if (collectionArr.indexOf(packet.vm) === -1) {
        singleFilterItems.push(getSingleFilterItemContent(localConfig, packet, suppressFlag));
      } else {
        multiFilterItems.push(getMultiFilterItemContent(localConfig, packet, suppressFlag));
      }
    });
    return { singleFilterItems, toggleableFilterItems, multiFilterItems };
  }

  function getToggleableFilterItemContent(localConfig, packet /*, suppressFlag -- not used for single items at present*/) {
    var defaultValues = localConfig.defaultValues || {},
      stickyFilters = localConfig.stickyFilters || [];

    return toggleableItemTemplate
      .replace(/#{vm}/gi, typeof defaultValues[packet.vm] === "undefined" ? packet.vm : packet.vm + "() != '" + defaultValues[packet.vm] + "'")
      .replace(/#{vmRaw}/gi, packet.vm)
      .replace(/#{hidden}/gi, stickyFilters.indexOf(packet.vm) === -1 ? "" : 'style="visibility: hidden; margin: 0; padding: 0;"')
      .replace(/#{binding}/gi, packet.displayBinding || "text")
      .replace(/#{display}/gi, packet.display)
      .replace(/#{ui}/gi, packet.ui)
      .replace(/#{displayClasses}/gi, packet.displayClasses ? packet.displayClasses() : "");
  }

  function getSingleFilterItemContent(localConfig, packet /*, suppressFlag -- not used for single items at present*/) {
    var defaultValues = localConfig.defaultValues || {},
      stickyFilters = localConfig.stickyFilters || [];

    return singleItemFilterBarTemplate
      .replace(/#{vm}/gi, typeof defaultValues[packet.vm] === "undefined" ? packet.vm : packet.vm + "() != '" + defaultValues[packet.vm] + "'")
      .replace(/#{vmRaw}/gi, packet.vm)
      .replace(/#{hidden}/gi, stickyFilters.indexOf(packet.vm) === -1 ? "" : 'style="visibility: hidden; margin: 0; padding: 0;"')
      .replace(/#{binding}/gi, packet.displayBinding || "text")
      .replace(/#{display}/gi, packet.display)
      .replace(/#{ui}/gi, packet.ui)
      .replace(/#{displayClasses}/gi, packet.displayClasses ? packet.displayClasses() : "");
  }

  function getMultiFilterItemContent(_, packet, suppressFlag) {
    let filterStart = multiFilterStartMap[suppressFlag || ""] || "",
      filterEnd = multiFilterEndMap[suppressFlag || ""] || "",
      template = packet.hideToggle ? noToggleMultiSearchItemFilterBarTemplate : multiSearchItemFilterBarTemplate,
      result = template
        .replace(/#{vm}/gi, packet.vm)
        .replace(/#{collection}/gi, packet.vm + "Collection")
        .replace(/#{displayClasses}/gi, packet.displayClasses ? packet.displayClasses() : "")
        .replace(/#{ui}/gi, packet.ui.toLowerCase())
        .replace(/#{filter-start}/gi, filterStart)
        .replace(/#{filter-end}/gi, filterEnd);

    if (filterStart) {
      result = result.replace(/<!-- start-toggle-include-exclude -->.*<!-- end-toggle-include-exclude -->/gi, "");
    }

    return result;
  }

  function advancedSearch(localConfig) {
    localConfig = localConfig || {};
    if (!localConfig.mapToArrays) localConfig.mapToArrays = [];

    return {
      preInitialize: function() {
        if (!this.constructor.__advancedSearchInitialSetupRun) {
          this.constructor.__advancedSearchInitialSetupRun = true;
          performInitialSetup(localConfig, this.constructor.config, this.constructor.prototype);
        }
      },
      initialize: function() {
        var bus = cr.createMessageBus();
        this.advancedSearchMessageBus = bus.getConnection("___advancedSearchBus___");

        if (localConfig.addStartDate || localConfig.addStartAndEndDates) {
          this.startDate(localConfig.defaultStartDate);
          this.startDateDisplay = ko.computed(function() {
            return moment(this.startDate()).format("YYYY-MM-DD");
          }, this);

          if (localConfig.addStartAndEndDates) {
            this.endDate(localConfig.defaultEndDate);
            this.endDateDisplay = ko.computed(function() {
              return new moment(this.endDate()).format("YYYY-MM-DD");
            }, this);

            this.dateRange = ko.computed(function() {
              if (moment(this.startDate()).isSame(ALL_DATES_VALUE, "day") && moment(this.endDate()).isSame(ALL_DATES_VALUE, "day"))
                return "All Dates";

              // conditional formatting to save space when the year is either not needed
              // or both years are the same
              var td = moment();
              var sd = moment(this.startDate());
              var ed = moment(this.endDate());

              if (td.year() === sd.year() && td.year() === ed.year()) return sd.format("MMM D") + " - " + ed.format("MMM D");
              else if (sd.year() === ed.year()) {
                return sd.format("MMM D") + " - " + ed.format("MMM D, YYYY");
              } else {
                return sd.format("MMM D, YYYY") + " - " + ed.format("MMM D, YYYY");
              }
            }, this);
          }
        }
        if (localConfig.restrictToOneFilter) {
          this.restrictToOneFilter = true;
        }

        //this is really hacky, and dumb, but ... I added the filterCount computed, which happens to clash with
        //filterCount computeds that various searchVms were and still are defining for themselves.  And since this
        //was by necessity being defined in the postInitialize, it turns out it was shadowing them.  The below
        //is my workaround for that.
        this._advancedSearchPluginInitialized = ko.observable(false);
        this.filterCount = ko.computed(
          () =>
            !this._advancedSearchPluginInitialized()
              ? 0
              : Object.keys(advancedSearchResultsMap)
                  .map(k => advancedSearchResultsMap[k].vm)
                  .reduce((acc, prop) => {
                    acc.indexOf(prop) < 0 && acc.push(prop);
                    return acc;
                  }, [])
                  .map(prop => +!!this.searchPropertyIsActive(prop))
                  .reduce((total, c) => c + total),
          0
        );
      },
      postInitialize: function() {
        if (typeof this.pageSize === "function" && this.pageSize.subscribe) {
          this.pageSize.subscribe(
            function(newPage) {
              if (this._syncingPageSize) return;
              this.setPageSize(+newPage);
            }.bind(this)
          );
        }

        var self = this;
        if (localConfig.manuallyBound) {
          //historical special case
          if (localConfig.manuallyBound.startDate) {
            localConfig.manuallyBound.startdate = localConfig.manuallyBound.startDate;
            delete localConfig.manuallyBound.startDate;
          }

          $.each(localConfig.manuallyBound, function(hashPropName, selectorPacket) {
            if (selectorPacket.boundToObservable) {
              self[selectorPacket.boundToObservable].subscribe(function(val) {
                if (self._syncingWithHash) return;
                self.setFilterItem(hashPropName, self[selectorPacket.boundToObservable]() || null);
              });
            }
          });
        }
        if (localConfig.manualSelectors) {
          $.each(localConfig.manualSelectors, function(vmProp, selectorInfo) {
            if (!$.isArray(selectorInfo)) {
              selectorInfo = [selectorInfo];
            }
            selectorInfo.forEach(function(selectorPacket) {
              if (typeof selectorPacket === "string") {
                $(selectorPacket).on("click", function(e) {
                  self.setFilterItem(
                    vmProp,
                    selectorPacket.manualProperty ? ko.dataFor(this)[selectorPacket.manualProperty]() : $(this).data("value")
                  );
                });
              } else {
                if (selectorPacket.rootSelector) {
                  $(selectorPacket.rootSelector).on("click", selectorPacket.delegatedSelector, function(e) {
                    self.setFilterItem(
                      vmProp,
                      selectorPacket.manualProperty ? ko.dataFor(this)[selectorPacket.manualProperty]() : $(this).data("value")
                    );
                  });
                } else {
                  $(selectorPacket.selector).on(selectorPacket.event || "click", function(e) {
                    if ($(this).is("input:checkbox")) {
                      //if false, don't set to false - remove from the hash
                      self.setFilterItem(vmProp, this.checked || null);
                    } else if ($(this).is("select")) {
                      self.setFilterItem(vmProp, this.value || null);
                    } else {
                      self.setFilterItem(
                        vmProp,
                        selectorPacket.manualProperty ? ko.dataFor(this)[selectorPacket.manualProperty]() : $(this).data("value")
                      );
                    }
                  });
                }
              }
            });
          });
        }
        for (var key in advancedSearchResultsMap) {
          var obj = advancedSearchResultsMap[key];
          if (obj.displayNotMapped) {
            if (!(obj.display in this)) {
              this[obj.display] = ko.observable("");
            }
          }
        }
        this._advancedSearchPluginInitialized(true);
      }
    };
  }

  function performInitialSetup(pluginConfig, vmConfig, pubs) {
    if (pluginConfig.addStartDate || pluginConfig.addStartAndEndDates) {
      vmConfig.basicObservables.push("startDate");
    }
    if (pluginConfig.addStartAndEndDates) {
      vmConfig.basicObservables.push("endDate");
    }

    pubs._collectionArr = pluginConfig.mapToArrays || [];
    pubs._staticSearchItems = pluginConfig.staticSearchItems || [];

    for (var key in advancedSearchResultsMap) {
      var obj = advancedSearchResultsMap[key],
        item,
        whichVm;

      item = pubs._collectionArr.filter(function(item) {
        return obj.vm === item || (item.name && item.name === obj.vm);
      })[0];

      if (item) {
        if (typeof item === "string") {
          vmConfig.mappedArrays.push({ name: obj.vm + "Collection", viewModelType: cr.viewModelFactory.commonViewModels.multiSeachInstanceVm });
        } else {
          whichVm = item.excludable
            ? cr.viewModelFactory.commonViewModels.multiSeachInstanceVm
            : cr.viewModelFactory.commonViewModels.multiSeachInstanceNonExcludableVm;
          vmConfig.mappedArrays.push({ name: obj.vm + "Collection", viewModelType: whichVm });
        }
      } else {
        if (vmConfig.basicObservables.indexOf(obj.vm) === -1) {
          vmConfig.basicObservables.push(obj.vm);
        }
        if (vmConfig.basicObservables.indexOf(obj.display) === -1 && !obj.displayNotMapped) {
          vmConfig.basicObservables.push(obj.display);
        }
      }
    }

    pubs._collectionArr = pubs._collectionArr.map(function(val) {
      return typeof val === "string" ? val : val.name;
    });
    pubs._advancedSearchConfig = pluginConfig;

    $.extend(pubs, getSearchVmExtensions(pluginConfig));
  }

  advancedSearch.advancedSearchResultsMap = advancedSearchResultsMap;
  advancedSearch.registerAdvancedSearchOptions = registerAdvSearchOptions;
  advancedSearch.unRegisterAdvancedSearchOptions = unRegisterAdvSearchOptions;

  return advancedSearch;
});
